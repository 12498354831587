/**
* Generated automatically at built-time (2025-01-02T06:47:34.339Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "eurocave",templateKey: "sites/88-7e9e2a98-2ba6-4bc0-bf64-feefb12bbebf"};
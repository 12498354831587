/**
* Generated automatically at built-time (2025-01-02T06:47:34.394Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-wineexpert-vinkyl",templateKey: "sites/88-23f88f7e-efc7-4b68-9162-666a2b099a88"};